import React, { useState, useEffect } from "react";
import "../styles/homepage.css";
import Carousel from "react-bootstrap/Carousel";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const HomePage = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    // Navigate to the desired URL programmatically
    navigate(`/home?category=${encodeURIComponent(category)}`);
  };

  //integrating get  method
  const [AddBanner, setAddBanner] = useState([]);
  const getAddBanner = async () => {
    try {
      let res = await axios.get(
        "https://students-databse.co.in/api/admin/gethomeslider"
      );
      if (res.status === 200) {
        setAddBanner(res.data.gethomeslider);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddBanner();
  }, []);

  //integrating get method
  const [Addmain, setAddmain] = useState([]);
  const getAddmain = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getmian");
      if (res.status === 200) {
        setAddmain(res.data.getmian);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddmain();
  }, []);

  //integrating get method
  const [Addcategory, setAddcategory] = useState([]);
  const getAddcategory = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
      if (res.status === 200) {
        const sortedCategories = res.data.getcategory.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAddcategory(sortedCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddcategory();
  }, []);


  return (
    <div>
      <div className="">
        {Addmain?.map((item, i) => {
          return (
            <div className="heading">
              <h3
                style={{
                  fontWeight: "bold",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {item.MainHeading}
              </h3>
            </div>
          );
        })}
      </div>
      <div>
        <Carousel fade>
          {AddBanner?.map((item, i) => {
            return (
              <Carousel.Item>
                <div className="carousel-img-container mb-2">
                  <div>
                    <img
                      className="imgcorousel1"
                      src={`https://students-databse.co.in/HomeSlider/${item?.SliderImage}`}
                      alt="First slide"
                    />
                  </div>
                  <Carousel.Caption className="captions">
                    <h3>{item.SliderText}</h3>
                    <p>{item.SliderTagline}</p>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <section id="homecarousel">
        <div className="container">
          <div className="row">
            <div className="col-md-3 ">
              <div className="categories">
                <div className="db-cat-heading">
                  <i
                    class="fa-solid fa-list-ul"
                    style={{ fontSize: "15px", margin: "10px" }}
                  ></i>
                  <h5>Database Categories</h5>
                </div>
                <div className="db-cat-name">
                  {Addcategory?.map((item, i) => {
                    return (
                      <button
                      key={i}
                      onClick={() => {
                        navigate("/home", { state: { categoryName: item.CategoryName } });
                      }}
                    >
                      <FaAngleRight className="greaterthan" /> {item.CategoryName}
                    </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
