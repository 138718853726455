import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import {
  LuActivity,
  LuBookMarked,
  LuFileQuestion,
  LuIndianRupee,
  LuListOrdered,
  LuLogOut,
  LuPackageX,
  LuUserCog,
} from "react-icons/lu";
import { FaQuestion, FaWeightHanging } from "react-icons/fa";
import { IoMdContact } from "react-icons/io";
import { FaShop, FaRegIdCard, FaCircleUser } from "react-icons/fa6";
import { GiFlatPlatform } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { AiOutlineSnippets } from "react-icons/ai";
import { GrGallery } from "react-icons/gr";
import { IoPeopleOutline, IoNewspaperOutline } from "react-icons/io5";
import { PiExamFill, PiHandshakeLight } from "react-icons/pi";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineTipsAndUpdates,
  MdOutlineKeyboardArrowUp,
  MdOutlineSupportAgent,
  MdOutlineAddComment,
  MdEventAvailable,
  MdSubject,
  MdOutlineKeyboardArrowLeft,
  MdOutlineReviews,
} from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { LuAlignHorizontalJustifyStart } from "react-icons/lu";
import { IoEarth } from "react-icons/io5";
import "../Admin/Admin.css";
import Navbar from "react-bootstrap/Navbar";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { MdLooksOne } from "react-icons/md";
import { PiNumberSquareTwoFill } from "react-icons/pi";
import { PiNumberSquareThreeFill } from "react-icons/pi";
import { PiNumberSquareFourFill } from "react-icons/pi";
import { PiNumberSquareFiveFill } from "react-icons/pi";
import { TiTick } from "react-icons/ti";
import { FaArrowsLeftRightToLine } from "react-icons/fa6";
import { FaPersonCircleQuestion } from "react-icons/fa6";
import { AiFillSetting, AiOutlineHome } from "react-icons/ai";
import { BsFillTelephoneFill, BsQuestionLg } from "react-icons/bs";
import { TbMessageFilled } from "react-icons/tb";
import { BsChatTextFill } from "react-icons/bs";
import { GiDatabase } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import { FaFileContract } from 'react-icons/fa';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FaPhoneSquareAlt } from 'react-icons/fa';
import { MdCategory } from "react-icons/md";
import { MdHomeMax } from "react-icons/md";
import axios from "axios";

const Side = () => {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [Home, setHome] = useState(false);

  // Responsive sidebar
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
 
  //integrating get  method  of database enquiry
  const [AddDatabasequry, setAddDatabasequry] = useState([]);
  const getAddDatabasequry = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getdatabase");
      if (res.status === 200) {
        setAddDatabasequry(res.data.getdatabase);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddDatabasequry();
  }, []);


   //integrating get  method of category enquiry
 const [Addcart, setAddcart] = useState([]);
 const getAddcart = async () => {
   try {
     let res = await axios.get("https://students-databse.co.in/api/user/getcartquery");
     if (res.status === 200) {
       setAddcart(res.data.getcartquery);
       
     }
   } catch (error) {
     console.log(error);
   }
 };

 useEffect(() => {
  getAddcart();
}, []);

  //integrating get  method of sample enquiry
  const [Addsample, setAddsample] = useState([]);
  const getAddsample = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getsample");
      if (res.status === 200) {
        setAddsample(res.data.getsample);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddsample();
  }, []);


    //integrating get  method of general enquiry
    const [Addgeneral, setAddgeneral] = useState([]);
    const getgeneral = async () => {
      try {
        let res = await axios.get(
          "https://students-databse.co.in/api/user/getgeneral"
        );
        if (res.status === 200) {
          setAddgeneral(res.data.getgeneral);
        }
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getgeneral();
    }, []);


      //integrating get method of category
  const [Addcategory, setAddcategory] = useState([]);
  const getAddcategory = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
      if (res.status === 200) {
        // Sort categories by _id in descending order
        const sortedCategories = res.data.getcategory.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAddcategory(sortedCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddcategory();
  }, []);


   //integrating get method of database list
   const [Adddatabase, setAdddatabase] = useState([]);
   const getAdddatabase = async () => {
     try {
       let res = await axios.get("https://students-databse.co.in/api/admin/getdatabaselist");
       if (res.status === 200) {
         // Sort categories by _id in descending order
         const sortedCategories = res.data.getdatabaselist.sort((a, b) =>
           b._id.localeCompare(a._id)
         );
         setAdddatabase(sortedCategories);
       }
     } catch (error) {
       console.log(error);
     }
   };
   useEffect(() => {
    getAdddatabase();
   }, []);

  return (
    <div>
      <Navbar expand="lg" className=" p-0">
        <button
          class="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample09"
          aria-controls="navbarsExample09"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
          style={{ margin: "10px" }}
        >
          <span>
            <GiHamburgerMenu style={{ color: "white" }} />
          </span>
        </button>
        <div
          class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarsExample09"
        >
          <div className="si09">
            <div style={{ width: "100%", justifyContent: "space-between" }}>
              <div
                className="lo-ad"
                style={{ background: "white", borderBottom: "1px solid white" }}
              >
                <div className="">
                    <img
                      src="../Assets/sdlogo.png"
                      alt="Logo"
                      className="admin-logo-img"
                      style={{ width: "100%" }}
                    />
                
                </div>
              </div>
              <div className="sidebar-close-icon" onClick={handleNavCollapse}>
                <AiOutlineClose />
              </div>
            </div>
            <ul>
             

              <Link to="/database_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdOutlineSupportAgent 
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Database Enquiry ( {AddDatabasequry?.length} ) </span>
                </li>
              </Link>

              <Link to="/cart_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaWeightHanging
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Cart Enquiry ( {Addcart?.length} ) </span>
                </li>
              </Link>

              <Link to="/sample_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <TbMessageFilled 
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Sample Enquiry ( {Addsample?.length} ) </span>
                </li>
              </Link>

              <Link to="/general_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <BsChatTextFill style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">General Enquiries ( {Addgeneral?.length} ) </span>
                </li>
              </Link>

              <Link to="/admin_category" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdCategory
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Category ( {Addcategory?.length} ) </span>
                </li>
              </Link>

              <Link to="/database_list" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <GiDatabase
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Database List ( {Adddatabase?.length} ) </span>
                </li>
              </Link>

              <Link to="/admin_homeslider" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <LuAlignHorizontalJustifyStart
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Home Slider</span>
                </li>
              </Link>

              
              <Link to="/homemain_text" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdHomeMax
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Main</span>
                </li>
              </Link>

              <Link to="/admin_aboutus" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaBuildingUser
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">About us </span>
                </li>
              </Link>

              <Link to="/terms_and_condition" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaFileContract
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Terms & Condition </span>
                </li>
              </Link>

              <Link to="/admin_disclaimer" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaExclamationTriangle
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Disclaimers </span>
                </li>
              </Link>

              <Link to="/admin_contactus" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <FaPhoneSquareAlt style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Contact Us</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Side;
