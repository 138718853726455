import React, { useState } from "react";
import axios from "axios";
import "../Admin/Admin.css";
import { IoEyeOffSharp } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

function AdminLogin() {
  const [REmail, setREmail] = useState("");
  const [RPassword, setRPassword] = useState("");

  const adminLogin = async () => {
    try {
      const config = {
        url: "admin/adminLogin",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        headers: { "content-type": "application/json" },
        data: { REmail: REmail, RPassword: RPassword },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully login");
        window.sessionStorage.setItem(
          "admin",
          JSON.stringify(res.data.success)
        );
        window.location.assign("/database_enquiry");
      }
    } catch (error) {
      alert("Please Enter Registered Email ID or Password");
      console.log(error);
    }
  };

  // passwrd view icon
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => {
    setEmailError(""); // Clear previous errors
    setREmail("");
    setShow9(true);
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(IoEyeOffSharp);

 

  // Email condition
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setREmail(inputEmail); // Updated to set the correct state

    // Check if the email is not empty and if it matches the pattern
    if (!inputEmail) {
      setEmailError("Email ID cannot be empty");
    } else if (!validateEmail(inputEmail)) {
      setEmailError("Please enter a valid Email ID");
    } else {
      setEmailError("");
    }
  };
  return (
    <>
      <div className="bg-img">
        <div className="add">
          <div className="container">
            <div className="fw_90">
              <div className="add_0">
                <div className="im09">
                  <div className="d-flex">
                    <img
                      src="../Assets/sdlogo.png"
                      alt="Logo"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "120px",
                      }}
                      className="admin-login-logo"
                    />
                  </div>
                </div>
                <div className="add-90">
                  <form>
                    <div className="sd_00 mb-2">
                      <label>Email</label> <br />
                      <input
                        type="email"
                        placeholder="Enter Your Email ID"
                        className="name_0"
                        value={REmail}
                        onChange={(e) => setREmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="sd_00 mb-2">
                      <label>Password</label>
                      <br />
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordVisible ? "text" : "password"} // Toggle input type
                          placeholder="Password"
                          className="name_0"
                          value={RPassword}
                          onChange={(e) => setRPassword(e.target.value)}
                          required
                          style={{ paddingRight: "40px" }}
                        />
                        <span
                          onClick={() => setPasswordVisible(!passwordVisible)} // Toggle visibility
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          {passwordVisible ? "👁️" : "🙈"}
                        </span>
                      </div>
                    </div>
                    <div className="sd_00 mt-2">
                      {" "}
                      <button
                        type="button"
                        style={{ background: "white", color: "black" }}
                        onClick={() => adminLogin()}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLogin;
