import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About";
import Term from "./components/Term";
import Disclaimer from "./components/Disclaimer";
import HomePage from "./components/HomePage";
import Whatsapp from "./components/WhatsApp";

// ADMIN PANEL
import AdminLogin from "./components/Admin/AdminLogin";
import Main from "./components/Admin/Main";
import AdminSlider from "./components/Admin/AdminSlider";
import DatabaseEnquiry from "./components/Admin/DatabaseEnquiry";
import CartEnquiry from "./components/Admin/CartEnquiry";
import SampleEnquiry from "./components/Admin/SampleEnquiry";
import GeneralEnquiry from "./components/Admin/GeneralEnquiry";
import DatabaseList from "./components/Admin/DatabaseList";
import Aboutus from "./components/Admin/Aboutus";
import TermsandCondition from "./components/Admin/TermsandCondition";
import Disclaimers from "./components/Admin/Disclaimers";
import AdminContactus from "./components/Admin/AdminContactus";
import AdminCategory from "./components/Admin/AdminCategory";
import MainText from "./components/Admin/MainText";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            element={
              <>
                {" "}
                <Navbar />
                <HomePage />
                <Footer />
              </>
            }
            path="/"
          />
          <Route
            element={
              <>
                {" "}
                <Navbar />
                <Home /> <Footer />
              </>
            }
            path="/home"
          />
          <Route
            element={
              <>
                {" "}
                <Navbar />
                <About /> <Footer />
              </>
            }
            path="/about"
          />
          <Route
            element={
              <>
                {" "}
                <Navbar />
                <Term /> <Footer />
              </>
            }
            path="/term"
          />
          <Route
            element={
              <>
                {" "}
                <Navbar />
                <Disclaimer /> <Footer />
              </>
            }
            path="/disclaimer"
          />

          {/* Admin panel  */}
          <Route
            path="/admin"
            element={
              <>
                <AdminLogin />
              </>
            }
          />

          <Route
            path="/admin_homeslider"
            element={
              <Main
                children={
                  <>
                    <AdminSlider />
                  </>
                }
              />
            }
          />
           <Route
            path="/homemain_text"
            element={
              <Main
                children={
                  <>
                    <MainText />
                  </>
                }
              />
            }
          />
          <Route
            path="/database_enquiry"
            element={
              <Main
                children={
                  <>
                    <DatabaseEnquiry />
                  </>
                }
              />
            }
          />
          <Route
            path="/cart_enquiry"
            element={
              <Main
                children={
                  <>
                    <CartEnquiry />
                  </>
                }
              />
            }
          />

          <Route
            path="/sample_enquiry"
            element={
              <Main
                children={
                  <>
                    <SampleEnquiry />
                  </>
                }
              />
            }
          />

          <Route
            path="/general_enquiry"
            element={
              <Main
                children={
                  <>
                    <GeneralEnquiry />
                  </>
                }
              />
            }
          />
             <Route
            path="/admin_category"
            element={
              <Main
                children={
                  <>
                    <AdminCategory />
                  </>
                }
              />
            }
          />
          <Route
            path="/database_list"
            element={
              <Main
                children={
                  <>
                    <DatabaseList />
                  </>
                }
              />
            }
          />
          <Route
            path="/admin_aboutus"
            element={
              <Main
                children={
                  <>
                    <Aboutus />
                  </>
                }
              />
            }
          />

          <Route
            path="/terms_and_condition"
            element={
              <Main
                children={
                  <>
                    <TermsandCondition />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_disclaimer"
            element={
              <Main
                children={
                  <>
                    <Disclaimers />
                  </>
                }
              />
            }
          />

          <Route
            path="/admin_contactus"
            element={
              <Main
                children={
                  <>
                    <AdminContactus />
                  </>
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    
    </div>
  );
};

export default App;
