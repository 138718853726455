import "../styles/about.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  //integrating get method
  const [Aboutus, setAboutus] = useState([]);
  const getAboutus = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getaboutus");
      if (res.status === 200) {
        setAboutus(res.data.getaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);

  //integrating get method
  const [Addcategory, setAddcategory] = useState([]);
  const getAddcategory = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
      if (res.status === 200) {
        // Sort categories by _id in descending order
        const sortedCategories = res.data.getcategory.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAddcategory(sortedCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddcategory();
  }, []);

  return (
    <div className="container home">
      <div className="row ">
        <div className="col-md-3 mb-5">
          <div className="categories">
            <div className="db-cat-heading">
              <i
                class="fas fa-bars"
                style={{ fontSize: "15px", margin: "10px" }}
              ></i>
              <h5>Database Categories</h5>
            </div>
            <div className="db-cat-name">
              {Addcategory?.map((item, i) => {
                return (
                  <button
                    key={i}
                    onClick={() => {
                      navigate("/home", {
                        state: { categoryName: item.CategoryName },
                      });
                    }}
                  >
                    <FaAngleRight className="greaterthan" /> {item.CategoryName}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-md-9">
          {Aboutus?.map((item, i) => {
            return (
              <div>
                <h3 className="about">{item.AboutusHeading}</h3>
                <p>{parse(`<div>${item.AboutusDescription}</div>`)}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default About;
