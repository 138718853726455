import React, { useState, useEffect } from "react";
import "../styles/footer.css";
import { Button, Modal, Table, Image } from "react-bootstrap";
import Whatsapp from "./WhatsApp";
import axios from "axios";

const Footer = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // validation
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    // Name validation
    if (!GUName) {
      formIsValid = false;
      errors["name"] = "Please enter your name.";
    } else if (!/^[a-zA-Z ]+$/.test(GUName)) {
      formIsValid = false;
      errors["name"] = "Name should only contain letters.";
    }

    // Email validation
    if (!GUEmail) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(GUEmail)) {
      formIsValid = false;
      errors["email"] = "Invalid email format.";
    }

    // Phone number validation
    if (!GUPhone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number.";
    } else if (GUPhone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Phone number should be 10 digits.";
    }

    setErrors(errors);
    return formIsValid;
  };
  // ====================CONTACT US FORM=========================//

  const formdata = new FormData();
  const [GUName, setGUName] = useState("");
  const [GUPhone, setGUPhone] = useState("");
  const [GUEmail, setGUEmail] = useState("");
  // const [GUMessage, setGUMessage] = useState("");
  const [QueryDate, setQueryDate] = useState("");

  const Addgeneralquery = async () => {
    try {
      if (!GUName) {
        return alert("Please add name");
      }
      if (!GUPhone) {
        return alert("Please add phone number");
      }
      if (!GUEmail) {
        return alert("Please add email id");
      }
      // if (!GUMessage) {
      //   return alert("Please add a message");
      // }

      // Generate the current date in the format 17-August-2024

      const config = {
        url: "user/general",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          GUName: GUName,
          GUPhone: GUPhone,
          GUEmail: GUEmail,
          // GUMessage: GUMessage,
          // QueryDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(
          "Thanks for expressing interest in our student database. Our team will contact you shortly."
        );
        getgeneral();
        handleClose();
        setGUName("");
        setGUPhone("");
        setGUEmail("");
        enquirymail();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const enquirymail = async () => {
    try {
      const config = {
        url: "user/enquirymail",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          GUName: GUName,
          GUPhone: GUPhone,
          GUEmail: GUEmail,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getgeneral");
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getgeneral();
  }, []);

  //integrating get method contactus
  const [Addcontact, setAddcontact] = useState([]);
  const getAddcontact = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcontactus");
      if (res.status === 200) {
        setAddcontact(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontact();
  }, []);

  return (
    <div id="footer">
      <Whatsapp />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="footer-content">
              <div>
                <a href="/about">About</a>
              </div>
              <div>
                <a href="/term">Terms</a>
              </div>
              <div>
                <a href="/disclaimer">Disclaimer</a>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            {Addcontact?.map((item, i) => {
              return (
                <div className="footer-email">
                  <a href="#">
                    <b>Contact us:</b> {item.CPhone}
                  </a>
                  <a href="#">
                    <b>Email:</b> {item.CEmail}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="col-md-2">
            <div className="footer-button">
              <Button
                style={{
                  backgroundColor: "navy",
                  border: "1px solid white",
                }}
                onClick={handleShow}
              >
                Enquiry Form
              </Button>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>Enquiry Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>
              Provide your information, and our team will follow up with you
              shortly
            </h5>

            <div className="row">
              <div className="do-sear mt-2">
                <label>
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  required
                  className="vi_0"
                  name="name"
                  placeholder="Enter Your Name"
                  onChange={(e) => setGUName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>
                  Email ID <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="vi_0"
                  required
                  name="email"
                  placeholder="Enter Your Email ID"
                  onChange={(e) => setGUEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>
                  Phone Number<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  className="vi_0"
                  required
                  name="number"
                  placeholder="Enter Your Phone Number"
                  onChange={(e) => setGUPhone(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={Addgeneralquery}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Footer;
