import React, { useState, useEffect } from "react";
import "../styles/navbar.css";
import { Button, Modal, Table, Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { PiPhoneCallFill } from "react-icons/pi";
import { IoIosMail } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";

const Navbar = () => {
  const navigate = useNavigate();


  const [showModal, setShowModal] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // enquiry modal
  const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // ====================CONTACT US FORM=========================//

  const formdata = new FormData();
  const [GUName, setGUName] = useState("");
  const [GUPhone, setGUPhone] = useState("");
  const [GUEmail, setGUEmail] = useState("");
  // const [GUMessage, setGUMessage] = useState("");
  const [QueryDate, setQueryDate] = useState("");

  const Addgeneralquery = async () => {
    try {
      if (!GUName) {
        return alert("Please add nameQS");
      }
      if (!GUPhone) {
        return alert("Please add phone number");
      }
      if (!GUEmail) {
        return alert("Please add email id");
      }
      // if (!GUMessage) {
      //   return alert("Please add a message");
      // }

      // Generate the current date in the format 17-August-2024

      const config = {
        url: "/user/general",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          GUName: GUName,
          GUPhone: GUPhone,
          GUEmail: GUEmail,
          // GUMessage: GUMessage,
          // QueryDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(
          "Thanks for expressing interest in our student database. Our team will contact you shortly."
        );
        getgeneral();
        handleClose1();
        setGUName("");
        setGUPhone("");
        setGUEmail("");
        enquirymail();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const enquirymail = async () => {
    try {
      const config = {
        url: "user/enquirymail",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          GUName: GUName,
          GUPhone: GUPhone,
          GUEmail: GUEmail,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getgeneral");
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };


  
  useEffect(() => {
    getgeneral();
  }, []);

  
  // validation
  const [errors, setErrors] = useState({});

  //integrating get method contactus
  const [Addcontact, setAddcontact] = useState([]);
  const getAddcontact = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcontactus");
      if (res.status === 200) {
        setAddcontact(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontact();
  }, []);


     //integrating get method
     const [Addcategory, setAddcategory] = useState([]);
     const getAddcategory = async () => {
       try {
         let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
         if (res.status === 200) {
           // Sort categories by _id in descending order
           const sortedCategories = res.data.getcategory.sort((a, b) =>
             b._id.localeCompare(a._id)
           );
           setAddcategory(sortedCategories);
         }
       } catch (error) {
         console.log(error);
       }
     };
     useEffect(() => {
       getAddcategory();
     }, []);

  return (
    <div id="navbar">
      <div className="container">
        <div className="row ">
          <div className="col-md-3">
            <div className="nav-logo-button">
              <a href="/">
                <div className="logo-img"></div>
              </a>
              <button onClick={handleShow} className="offcanva fchyft">
                <i class="fas fa-bars" style={{ fontSize: "26px" }}></i>
              </button>
            </div>
          </div>
          <div className="col-md-9 ">
            <div className="nav-contact-email-enquiry">
              {Addcontact?.map((item, i) => {
                return(
                  <div className="nav-contact-email" key={i}>
                  {/* Email link */}
                  <a href={`mailto:${item.CEmail}`} style={{textDecoration: 'none'}}>
                    <IoIosMail style={{fontSize:"25px", color:"#00B100"}} />{" "}
                    {item.CEmail}
                  </a>
                  {/* Phone call link */}
                  <a href={`tel:${item.CPhone}`} style={{marginLeft: "-64px", textDecoration: 'none'}}>
                    <PiPhoneCallFill style={{fontSize:"22px", color:"#00B100"}} />{" "}
                    {item.CPhone}
                  </a>
                </div>
                );
              })}
             
              <div className="nav-button">
                <Button onClick={handleShow1}> Enquiry Form</Button>
                <Modal show={showModal} onClose={() => setShowModal(false)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        {/* <Button variant="primary" onClick={handleShow}>
      <i class="fas fa-bars" style={{fontSize:'24px'}}></i>
      </Button> */}

        <Offcanvas show={show} onHide={handleClose} style={{ width: "100%" }}>
          {/* <Offcanvas.Body closeButton>
            <div className="navbarn">
              <div className="db-cat-heading">
                <i
                  class="fas fa-bars"
                  style={{ fontSize: "15px", margin: "10px" }}
                ></i>
                <h5>Database Categories</h5>
              </div>
              <ul className="navbar-items">
                <li>
                  <a href="/home">MBA, BBA, BBM Entrance Exams</a>
                </li>
                <li>
                  <a href="/home">Engineer can do anything</a>
                </li>
                <li>
                  <a href="/home">Final Year, Graduates</a>
                </li>
                <li>
                  <a href="/home">Medical Aspirants</a>
                </li>
                <li>
                  <a href="/home">MBA, BBA, BBM Entrance Exams</a>
                </li>
                <li>
                  <a href="/home">Engeering</a>
                </li>
              </ul>
            </div>
          </Offcanvas.Body> */}
           <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body style={{ padding: "2px 20px" }}>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ alignItems: "center" }}
                >
                  <Nav.Link href="/home" className="tail-text">
                  <div className="db-cat-name">
                  {Addcategory?.map((item, i) => {
                    return (
                      <button
                      key={i}
                      onClick={() => {
                        navigate("/home", { state: { categoryName: item.CategoryName } });
                      }}
                    >
                      <FaAngleRight className="greaterthan" /> {item.CategoryName}
                    </button>
                    );
                  })}
                </div>
                   
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
        </Offcanvas>
      </>

      <Modal show={show1} onHide={handleClose1} style={{ zIndex: "99999" }}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>Enquiry Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h5>Provide your information, and our team will follow up with you shortly</h5>

          <div className="row">
            <div className="do-sear mt-2">
              <label>
                Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                className="vi_0"
                name="name"
                placeholder="Enter Your Name"
                onChange={(e) => setGUName(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>
                Email ID <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                className="vi_0"
                required
                name="email"
                placeholder="Enter Your Email ID"
                onChange={(e) => setGUEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="do-sear mt-2">
              <label>
                Phone Number<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="number"
                className="vi_0"
                required
                name="number"
                placeholder="Enter Your Phone Number"
                onChange={(e) => setGUPhone(e.target.value)}
              />
            </div>
          </div>
         
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex">
            <Button
              className="mx-2 modal-close-btn"
              variant=""
              onClick={handleClose1}
            >
              Close
            </Button>
            <Button
              className="mx-2 modal-add-btn"
              variant=""
              onClick={Addgeneralquery}
            >
              Submit
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Navbar;
