import React, { useState, useEffect } from "react";
import "../styles/home.css";
import Download from "./Download";
import AddToCart from "./AddToCart";
import { FaAngleRight } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { FaCartShopping } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { FaQuestionCircle } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { BsSearch } from "react-icons/bs";

const Home = () => {
  // Enquiry modal
  const [show4, setShow4] = useState(false);
  let [category1, setCategory1] = useState(true);
  let [modaldata, setmodaldata] = useState({});
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setmodaldata(item);
  };

  // CART Enquiry modal
  const [show3, setShow3] = useState(false);
  let [modaldata3, setmodaldata3] = useState({});
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setmodaldata3(item);
  };

  // Download broucher Enquiry modal
  const [show2, setShow2] = useState(false);
  let [modaldata2, setmodaldata2] = useState({});
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setmodaldata2(item);
  };

  // ===================CATEGORY==========================//
  //integrating get method
  const [Addcategory, setAddcategory] = useState([]);
  const getAddcategory = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/admin/getcategory");
      if (res.status === 200) {
        // Sort categories by _id in descending order
        const sortedCategories = res.data.getcategory.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAddcategory(sortedCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddcategory();
  }, []);

  // ====================DATABASE LIST=========================//
  //integrating get method
  const [Adddatabase, setAdddatabase] = useState([]);
  const getAdddatabase = async () => {
    try {
      let res = await axios.get(
        "https://students-databse.co.in/api/admin/getdatabaselist"
      );
      if (res.status === 200) {
        // Sort categories by _id in descending order
        const sortedCategories = res.data.getdatabaselist.sort((a, b) =>
          b._id.localeCompare(a._id)
        );
        setAdddatabase(sortedCategories);
        setNoChangeData(res.data.getdatabaselist);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAdddatabase();
  }, []);

  // =====================DATABASE ENQUIRY========================//
  // validation
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    // Name validation
    if (!DUName) {
      formIsValid = false;
      errors["name"] = "Please enter your name.";
    } else if (!/^[a-zA-Z ]+$/.test(DUName)) {
      formIsValid = false;
      errors["name"] = "Name should only contain letters.";
    }

    // Email validation
    if (!DUEmail) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(DUEmail)) {
      formIsValid = false;
      errors["email"] = "Invalid email format.";
    }

    // Phone number validation
    if (!DUPhone) {
      formIsValid = false;
      errors["phone"] = "Please enter your phone number.";
    } else if (DUPhone.length !== 10) {
      formIsValid = false;
      errors["phone"] = "Phone number should be 10 digits.";
    }

    setErrors(errors);
    return formIsValid;
  };
  // ====================ENQUIRY FORM=========================//
  const [edit, setEdit] = useState(true); // getting place name by default

  const [DUName, setDUName] = useState("");
  const [DUPhone, setDUPhone] = useState("");
  const [DUEmail, setDUEmail] = useState("");
  // const [DUMessage, setDUMessage] = useState("");
  const [DatabaseCatname, setDatabaseCatname] = useState("");
  const [DatabaseClgname, setDatabaseClgname] = useState("");
  const [DatabaseAcademicyear, setDatabaseAcademicyear] = useState("");

  const Adddatabasequery = async () => {
    if (validateForm()) {
      try {
        // if (!DUMessage) {
        //   return alert("please add your message");
        // }
        const config = {
          url: "/user/database",
          method: "post",
          baseURL: "https://students-databse.co.in/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            DatabaseCatname: modaldata?.DatabaselistCatName,
            DatabaseClgname: modaldata?.InstituteName,
            DatabaseAcademicyear: modaldata?.AcadmeicYear,
            DUName: DUName,
            DUPhone: DUPhone,
            DUEmail: DUEmail,
            // DUMessage: DUMessage,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert(
            "Thanks for expressing interest in our student database. Our team will contact you shortly."
          );
          getAddDatabasequry();
          sendmail();
          setDUName("");
          setDUPhone("");
          setDUEmail("");
          // setDUMessage("");
          handleClose4();
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.msg);
      }
    }
  };

  const sendmail = async () => {
    try {
      const config = {
        url: "user/sendmail",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          DatabaseCatname: modaldata?.DatabaselistCatName,
          DatabaseClgname: modaldata?.InstituteName,
          DUName: DUName,
          DUPhone: DUPhone,
          DUEmail: DUEmail,
          // DUMessage: DUMessage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [AddDatabasequry, setAddDatabasequry] = useState([]);
  const getAddDatabasequry = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getdatabase");
      if (res.status === 200) {
        setAddDatabasequry(res.data.getdatabase);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddDatabasequry();
  }, []);

  // =====================CART ENQUIRY========================//
  // validation
  const [errorss, setErrorss] = useState({});
  const validateForms = () => {
    let formIsValids = true;
    let errorss = {};

    // Name validation
    if (!CUName) {
      formIsValids = false;
      errorss["name"] = "Please enter your name.";
    } else if (!/^[a-zA-Z ]+$/.test(CUName)) {
      formIsValids = false;
      errorss["name"] = "Name should only contain letters.";
    }

    // Email validation
    if (!CUEmail) {
      formIsValids = false;
      errorss["email"] = "Please enter your email.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(CUEmail)) {
      formIsValids = false;
      errorss["email"] = "Invalid email format.";
    }

    // Phone number validation
    if (!CUPhone) {
      formIsValids = false;
      errorss["phone"] = "Please enter your phone number.";
    } else if (CUPhone.length !== 10) {
      formIsValids = false;
      errorss["phone"] = "Phone number should be 10 digits.";
    }

    setErrorss(errorss);
    return formIsValids;
  };
  // ====================CART ENQUIRY FORM=========================//

  const [CUName, setCUName] = useState("");
  const [CUPhone, setCUPhone] = useState("");
  const [CUEmail, setCUEmail] = useState("");
  // const [CUMessage, setCUMessage] = useState("");
  const [CartCatname, setCartCatname] = useState("");
  const [CartClgname, setCartClgname] = useState("");
  // const [CartAcademicyear, setCartAcademicyear] = useState("");

  const Addcartquery = async () => {
    if (validateForms()) {
      try {
        // if (!CUMessage) {
        //   return alert("please add your message");
        // }
        const config = {
          url: "/user/cartquery",
          method: "post",
          baseURL: "https://students-databse.co.in/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            CartCatname: modaldata3?.DatabaselistCatName,
            CartClgname: modaldata3?.InstituteName,
            CUName: CUName,
            CUPhone: CUPhone,
            CUEmail: CUEmail,
            // CUMessage: CUMessage,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert(
            "Thanks for expressing interest in our student database. Our team will contact you shortly."
          );
          getAddcart();
          cartmail();
          setCUName("");
          setCUPhone("");
          setCUEmail("");
          // setCUMessage("");
          handleClose3();
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.msg);
      }
    }
  };

  const cartmail = async () => {
    try {
      const config = {
        url: "user/cartmail",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          CartCatname: modaldata3?.DatabaselistCatName,
          CartClgname: modaldata3?.InstituteName,
          CUName: CUName,
          CUPhone: CUPhone,
          CUEmail: CUEmail,
          // CUMessage: CUMessage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [Addcart, setAddcart] = useState([]);
  const getAddcart = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getcartquery");
      if (res.status === 200) {
        setAddcart(res.data.getcartquery);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcart();
  }, []);

  // =====================DOWNLOAD BROCHURE ENQUIRY========================//
  // validation
  const [errorsss, setErrorsss] = useState({});
  const validateFormss = () => {
    let formIsValidss = true;
    let errorsss = {};

    // Name validation
    if (!SUName) {
      formIsValidss = false;
      errorsss["name"] = "Please enter your name.";
    } else if (!/^[a-zA-Z ]+$/.test(SUName)) {
      formIsValidss = false;
      errorss["name"] = "Name should only contain letters.";
    }

    // Email validation
    if (!SUEmail) {
      formIsValidss = false;
      errorsss["email"] = "Please enter your email.";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(SUEmail)) {
      formIsValidss = false;
      errorsss["email"] = "Invalid email format.";
    }

    // Phone number validation
    if (!SUPhone) {
      formIsValidss = false;
      errorsss["phone"] = "Please enter your phone number.";
    } else if (SUPhone.length !== 10) {
      formIsValidss = false;
      errorsss["phone"] = "Phone number should be 10 digits.";
    }

    setErrorsss(errorsss);
    return formIsValidss;
  };
  // ====================DOWNLOAD BROCHURE ENQUIRY FORM=========================//

  const [SUName, setSUName] = useState("");
  const [SUPhone, setSUPhone] = useState("");
  const [SUEmail, setSUEmail] = useState("");
  // const [SUMessage, setSUMessage] = useState("");
  const [SampleCatname, setSampleCatname] = useState("");
  const [SampleClgname, setSampleClgname] = useState("");
  const [SampleAcademicyear, setSampleAcademicyear] = useState("");

  const Addsamplequery = async () => {
    if (validateFormss()) {
      try {
        // if (!SUMessage) {
        //   return alert("please add your message");
        // }
        const config = {
          url: "user/sample",
          method: "post",
          baseURL: "https://students-databse.co.in/api/",
          headers: { "Content-Type": "application/json" },
          data: {
            SampleCatname: modaldata2?.DatabaselistCatName,
            SampleClgname: modaldata2?.InstituteName,
            SampleAcademicyear: modaldata2?.AcadmeicYear,
            SUName: SUName,
            SUPhone: SUPhone,
            SUEmail: SUEmail,
            // SUMessage: SUMessage,
          },
        };
        let res = await axios(config);
        if (res.status === 200) {
          alert(
            "Thanks for expressing interest in our student database. Our team will contact you shortly."
          );
          getAddsample();
          samplemail();
          setSUName("");
          setSUPhone("");
          setSUEmail("");
          // setSUMessage("");
          handleClose2();
        }
      } catch (error) {
        console.log(error);
        alert(error.response.data.msg);
      }
    }
  };

  const samplemail = async () => {
    try {
      const config = {
        url: "user/samplemail",
        method: "post",
        baseURL: "https://students-databse.co.in/api/",
        header: { "content-type": "application/json" },
        data: {
          SampleCatname: modaldata2?.DatabaselistCatName,
          SampleClgname: modaldata2?.InstituteName,
          SUName: SUName,
          SUPhone: SUPhone,
          SUEmail: SUEmail,
          // SUMessage: SUMessage,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
      }
    } catch (error) {
      console.log(error);
    }
  };

  //integrating get  method
  const [Addsample, setAddsample] = useState([]);
  const getAddsample = async () => {
    try {
      let res = await axios.get("https://students-databse.co.in/api/user/getsample");
      if (res.status === 200) {
        setAddsample(res.data.getsample);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddsample();
  }, []);

  // filtering data
  const [selectedCategory, setSelectedCategory] = useState("");

  // Retrieve category name from navigation state
  const location = useLocation();

  useEffect(() => {
    if (location.state?.categoryName) {
      setSelectedCategory(location.state.categoryName);
    }
  }, [location.state?.categoryName]);

  // Search filter
  const [nochangedata, setNoChangeData] = useState([]);
  const [searchH, setSearchH] = useState("");

  const handleFilterH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchH(searchTerm);
    if (searchTerm !== "") {
      const filteredData = nochangedata.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm)
        )
      );
      setAdddatabase(filteredData);
    } else {
      setAdddatabase(nochangedata);
    }
  };

  return (
    <section id="home">
      <div className="container home">
        <div className="row ">
          <div className="col-md-3 mb-5">
            <div className="categories">
              <div className="db-cat-heading">
                <i
                  className="fa-solid fa-list-ul"
                  style={{ fontSize: "15px", margin: "10px" }}
                ></i>
                <h5>Database Categories</h5>
              </div>
              <div className="db-cat-name">
                {Addcategory?.map((item, i) => {
                  return (
                    <button
                      onClick={() => {
                        setSelectedCategory(item.CategoryName);
                        setCategory1(true);
                      }}
                    >
                      <FaAngleRight className="greaterthan" />{" "}
                      {item.CategoryName}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          {category1 && (
            <div className="col-md-9 mb-3">
              <div className="abc">
                <div className="col-lg-8 d-flex justify-content-center mb-3">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      aria-describedby="basic-addon1"
                      onChange={handleFilterH}
                      value={searchH}
                    />
                    <span
                      className="input-group-text"
                      id="basic-addon1"
                      style={{ backgroundColor: "#0082F2", color: "white" }}
                    >
                      <BsSearch />
                    </span>
                  </div>
                </div>
              </div>
              <div className="">
                <h4>{selectedCategory}</h4>
              </div>
              {Adddatabase?.filter(
              (dbItem) => dbItem.DatabaselistCatName === selectedCategory 
              )
              .sort((a, b) => {
                if (a.priority === 0 && b.priority !== 0) return 1; // Move items with priority 0 to the end
                if (b.priority === 0 && a.priority !== 0) return -1; // Move items with priority 0 to the end
                return a.priority - b.priority; // Default sorting by ascending priority
              })
              .map((item, i) => {
                return (
                  <div>
                    <div key={i}>
                      <div className="all-database-content mb-3">
                        <div className="database-content">
                          <div
                            className="row"
                            style={{ background: "#f0f0f0 ", padding: "6px" }}
                          >
                            <div className="col-md-6">
                              <div className="details-content ">
                                <div className="mb-3">
                                  <h5>
                                    <FaAngleRight className="greater" />{" "}
                                    {item?.InstituteName}
                                  </h5>
                                </div>
                                <div className="record-count mb-2">
                                  <b style={{ color: "blue" }}>
                                    Record Count:{" "}
                                  </b>
                                  <span style={{ color: "#00B100" }}>
                                    {item?.Records}
                                  </span>
                                </div>
                                <div className="record-count mb-2">
                                  <b style={{ color: "blue" }}>Details: </b>
                                  <span style={{ color: "#00B100" }}>
                                    {item?.Fields}
                                  </span>
                                </div>
                                <div className="price mb-2">
                                  <b style={{ color: "blue" }}>Price: </b>

                                  <span style={{ color: "#00B100" }}>
                                    <FaIndianRupeeSign /> {new Intl.NumberFormat('en-IN').format(item?.Price)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="details-button">
                                <Button
                                  variant=""
                                  onClick={() => handleShow4(item)}
                                  style={{
                                    backgroundColor: "#00B100",
                                    color: "white",
                                  }}
                                >
                                  <FaQuestionCircle
                                    style={{ fontSize: "18px" }}
                                  />{" "}
                                  Enquire Now
                                </Button>

                                <Button onClick={() => handleShow3(item)}>
                                  <FaCartShopping /> Add to Cart
                                </Button>

                                <Button
                                  variant=""
                                  onClick={() => handleShow2(item)}
                                  style={{
                                    backgroundColor: "#00B100",
                                    color: "white",
                                  }}
                                >
                                  <FaDownload /> Download Sample
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}


              
            </div>
          )}

          {/* Modal for Enquiry */}
          <Modal show={show4} onHide={handleClose4} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>Enquiry Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                Provide your information, and our team will follow up with you
                shortly.
              </h5>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="vi_0"
                    name="name"
                    placeholder="Enter Your Name"
                    onChange={(e) => setDUName(e.target.value)}
                  />
                  {errors.name && (
                    <div style={{ color: "red" }}>{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Email ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="vi_0"
                    required
                    name="email"
                    placeholder="Enter Your Email ID"
                    onChange={(e) => setDUEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div style={{ color: "red" }}>{errors.email}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="vi_0"
                    required
                    name="number"
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => setDUPhone(e.target.value)}
                  />
                  {errors.phone && (
                    <div style={{ color: "red" }}>{errors.phone}</div>
                  )}
                </div>
              </div>
              {/* <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Message<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    value={DUMessage}
                    className="vi_0"
                    required
                    placeholder="Enter Your Message"
                    onChange={(e) => setDUMessage(e.target.value)}
                  />
                </div>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={() => {
                    Adddatabasequery();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* CART Modal for Enquiry */}
          <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Cart Enquiry Form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                Kindly provide your information to proceed with the database
                purchase.
              </h5>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="vi_0"
                    name="name"
                    placeholder="Enter Your Name"
                    onChange={(e) => setCUName(e.target.value)}
                  />
                  {errorss.name && (
                    <div style={{ color: "red" }}>{errorss.name}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Email ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="vi_0"
                    required
                    name="email"
                    placeholder="Enter Your Email ID"
                    onChange={(e) => setCUEmail(e.target.value)}
                  />
                  {errorss.email && (
                    <div style={{ color: "red" }}>{errorss.email}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="vi_0"
                    required
                    name="number"
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => setCUPhone(e.target.value)}
                  />
                  {errorss.phone && (
                    <div style={{ color: "red" }}>{errorss.phone}</div>
                  )}
                </div>
              </div>
              {/* <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Message<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    className="vi_0"
                    value={CUMessage}
                    required
                    placeholder="Enter Your Message"
                    onChange={(e) => setCUMessage(e.target.value)}
                  />
                </div>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={() => {
                    Addcartquery();
                  }}
                >
                  Sumbit
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* download brochure Modal for Enquiry */}
          <Modal show={show2} onHide={handleClose2} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Brochure Enquiry Form
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                To access the database sample download, please submit your
                details.
              </h5>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="vi_0"
                    name="name"
                    placeholder="Enter Your Name"
                    onChange={(e) => setSUName(e.target.value)}
                  />
                  {errorsss.name && (
                    <div style={{ color: "red" }}>{errorsss.name}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Email ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    className="vi_0"
                    required
                    name="email"
                    placeholder="Enter Your Email ID"
                    onChange={(e) => setSUEmail(e.target.value)}
                  />
                  {errorsss.email && (
                    <div style={{ color: "red" }}>{errorsss.email}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Phone Number<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="number"
                    className="vi_0"
                    required
                    name="number"
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => setSUPhone(e.target.value)}
                  />
                  {errorsss.phone && (
                    <div style={{ color: "red" }}>{errorsss.phone}</div>
                  )}
                </div>
              </div>
              {/* <div className="row">
                <div className="do-sear mt-2">
                  <label>
                    Message<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    value={SUMessage}
                    className="vi_0"
                    required
                    placeholder="Enter Your Message"
                    onChange={(e) => setSUMessage(e.target.value)}
                  />
                </div>
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose2}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={() => {
                    Addsamplequery();
                  }}
                >
                  Sumbit
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Home;
